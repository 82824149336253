<template>
  <div>
    <div class="container card p-2">
      <div class="print_header_page mb-3">
        <!-- <h1>Narxlarni chop etish</h1> -->
        <b-tabs
          align="left"
        >
          <b-tab
            active
            title="Narxlarni chop etish"
            @click="openTab(1)"
          />
          <b-tab
            title="Shtrix-kodni chop etish"
            @click="openTab(2)"
          />
        </b-tabs>
        <div
          v-if="type == 1"
          class="d-flex"
        >
          <div class="mr-5">
            <b>O'lcham</b>
            <b-form-select
              v-model="size"
              name="name"
              value-field="id"
              text-field="name"
              placeholder="O'lcham"
              :options="[
                { name: 'Katta o`lcham(4talik)', id: 4 },
                { name: 'Kichik o`lcham(6talik)', id: 6 },
              ]"
            />
          </div>
          <b-button
            size="sm"
            variant="primary"
            @click="print"
          >
            <feather-icon
              icon="PrinterIcon"
              size="12"
            />
            <span class="ml-25 align-middle">Chop etish</span>
          </b-button>
        </div>
      </div>
      <div
        v-if="type==2"
        class="d-flex justify-content-center align-center"
      >
        <div
          class="add-btn cursor-pointer"
          @click="$refs.barcode.visible = true"
        >
          <feather-icon icon="PlusIcon" /> Mahsulotni tanlash
        </div>
      </div>
      <div
        v-else
        class="bg-white"
      >
        <b-overlay :show="isPrintingProcess">
          <div id="print-content">
            <div :class="size == 6 ? 'landscapePage' : ''">
              <price-tag
                ref="priceTag"
                :products="products"
                :shop="shop"
                :size="size"
                :is-printing-process="isPrintingProcess"
                @duplicate="duplicate"
                @setProduct="setProduct"
              />
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <barcode ref="barcode" />

  </div>
</template>

<script>
import priceTag from './price_tag.vue'
import barcode from './barcode.vue'

export default {
  name: 'PriceTagIndex',
  components: { priceTag, barcode },
  data() {
    return {
      type: 1,
      products: [
        // {
        //   id: 2754,
        //   parent_id: 2578,
        //   name_uz: 'Steklo Samsung S23 FULL GLUE BLACK',
        //   name_ru: null,
        //   level: 3,
        //   path: '37,2089,2578',
        //   unit_of_measure_id: 1,
        //   cost: null,
        //   is_cost_price: 1,
        //   hired_worker_user_id: null,
        //   created_at: '2023-10-28T23:20:04.000000Z',
        //   updated_at: '2023-11-02T04:54:29.000000Z',
        //   cost_dollar: '122.95',
        //   price: 15580150,
        //   price_monthly_12: 1550000,
        //   price_monthly_18: 550000,
        //   price_monthly_24: 550000,
        //   barcode: '000000005566',
        // },
      ],
      size: 4,
      shop: 'Salomhayot',
      loading: false,
      isPrintingProcess: false,
    }
  },
  methods: {
    duplicate(category) {
      for (let i = 0; i < this.$refs.priceTag.length; i++) {
        if (!this.$refs.priceTag[i].category) {
          this.$refs.priceTag[i].category = category
          return true
        }
      }
      return ''
    },
    setProduct(val) {
      this.products.push(val)
    },
    openTab(tab) {
      this.type = tab
    },
    print() {
      const pageType = this.size == 4 ? 'portrait' : 'A4 landscape'
      const css = `@page { size: ${pageType}; }`
      const page = document.getElementById('print-content')
      const style = document.createElement('style')

      style.type = 'text/css'
      style.media = 'print'

      if (style.styleSheet) {
        style.styleSheet.cssText = css
      } else {
        style.appendChild(document.createTextNode(css))
      }
      page.appendChild(style)
      setTimeout(() => {
        window.print()
      }, 100)
    },
  },
}
</script>
<style lang="scss">
@import "@/assets/scss/price-tag-print";
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 0rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  .content-header {
    display: none !important;
  }

  .brak-page {
    page-break-after: always;
  }
  .copyPrintBtn {
    display: none !important;
  }
  .deletePrintBtn {
    display: none !important;
  }
  .custom-row {
    margin: 0 !important;
    padding: 0 !important;
  }
  .a4-page {
    // border: none;
  }
  .btn-scroll-to-top {
    display: none !important;
  }
  .print_header_page {
    display: none !important;
  }
  .add-btn {
    display: none !important;
  }
}
</style>
