<template>
  <div>
    <!-- 777 / {{ visible }} -->
    <div class="d-flex justify-content-center align-center">
      <div
        class="add-btn cursor-pointer"
        @click="openModal"
      >
        <feather-icon icon="PlusIcon" /> Mahsulot qo'shish
      </div>
    </div>
    <div class="a4-page">
      <div class="custom-row">
        <div
          v-for="(product, index) in products"
          :key="index"
          class="custom-col"
        >
          <div class="price-tag bg-white position-relative">
            <div class="d-flex justify-content-end">
              <div
                class="copyPrintBtn"
                @click="copyProduct(index)"
              >
                <feather-icon icon="CopyIcon" />
              </div>
              <div
                class="deletePrintBtn"
                @click="deleteProduct(index)"
              >
                <feather-icon icon="Trash2Icon" />
              </div>
            </div>
            <div class="logo-text">
              SALOMHAYOT
            </div>
            <div class="price">
              <span>
                {{ product.price | formatPrice }}
              </span>
            </div>
            <div class="name">
              {{ product.name_uz }}
            </div>
            <div class="first_payment">
              <p><b>Boshlang'ich to'lov: </b> {{ product.first_payment | formatPrice }} so'm</p>
            </div>
            <div class="py-30">
              <div class="monthly-price">
                <span class="month">12 oy</span>
                <div class="mprice">
                  {{ product.price_monthly_12 | formatPrice }}
                  <span>so'mdan</span>
                </div>
              </div>
              <div class="monthly-price">
                <span class="month">18 oy</span>
                <div class="mprice">
                  {{ product.price_monthly_18 | formatPrice }}
                  <span>so'mdan</span>
                </div>
              </div>
              <div class="monthly-price">
                <span class="month">24 oy</span>
                <div class="mprice">
                  {{ product.price_monthly_24 | formatPrice }}
                  <span>so'mdan</span>
                </div>
              </div>
            </div>
            <div class="code">
              <span>
                {{ product.barcode?Number(product.barcode):'0' }}
              </span>
            </div>
          </div>
          <div
            v-if="(index + 1) % size == 0"
            class="brak-page"
          />
        </div>
      </div>
    </div>
    <save
      v-if="visible"
      ref="saveForm"
      @onSuccess="setProduct"
    />
  </div>
</template>

<script>
import save from './save.vue'

export default {
  name: 'PriceTag',
  components: { save },
  props: {
    products: {
      type: Array,
      default() {
        return []
      },
    },
    size: {
      type: Number,
      default() {
        return 4
      },
    },
    isPrintingProcess: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      category: null,
      visible: true,
      productForm: {
        p0_category_id: null,
        p1_category_id: null,
        category_id: null,
      },
    }
  },
  computed: {
  },
  methods: {
    openModal() {
      this.visible = true
      setTimeout(() => {
        this.$refs.saveForm.visible = true
      }, 0)
    },
    setProduct(val) {
      this.$refs.saveForm.visible = false
      this.visible = false
      this.$emit('setProduct', val)
    },
    copyProduct(index) {
      const copiedProduct = this.products[index]
      this.products.push(copiedProduct)
    },
    deleteProduct(index) {
      this.products.splice(index, 1)
    },
  },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/price-tag-print.scss";
</style>
