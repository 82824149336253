<template>
  <div>
    <b-modal
      v-model="visible"
      cancel-variant="outline-secondary"
      :ok-title="$t('Сохранить')"
      no-close-on-backdrop
      :cancel-title="$t('Отмена')"
      centered
      :title="title"
      @ok.prevent="save"
    >
      <validation-observer
        ref="form"
        #default="{ invalid }"
      >
        <b-form class="mt-2">
          <b-overlay :show="!product_categories.data">
            <b-form-group
              :label="$t('Mahsulot')"
              label-for="category"
            >
              <!-- {{ category }} -->
              <validation-provider
                #default="{ errors }"
                name="category"
                rules="required"
              >
                <v-select
                  id="category"
                  ref="vSelect"
                  v-model="category"
                  :state="errors.length > 3 ? false : null"
                  :options="product_categories.data"
                  label="name_uz"
                  @input="changeCategory"
                >
                  <span slot="no-options"> Kechirasiz, tovar topilmadi </span>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-overlay>

          <hr>
          <!-- <b-form-group
          v-if="category"
          :label="$t('Mahsulot nomi')"
          label-for="letter"
        >
          <b-form-input
            id="letter"
            v-model="category.name_uz"
            name="letter"
          />
        </b-form-group> -->
          <!-- <b-form-group
            v-if="category"
            :label="$t('Barkodni tanlang')"
            label-for="barcode"
          >
            <v-select
              id="barcode"
              v-model="barcodeProduct"
              :options="barcodesProduct"
              label="barcode"
              @input="changeBarcode"
            />
          </b-form-group> -->
          <div v-if="barcodesProduct && !barcodesProduct.length" class="text-center text-muted">
            Ushbu mahsulot hozircha mavjud emas
          </div>
          <div
            v-for="(item, index) in barcodesProduct"
            :key="index"
          >
            <div id="qr">
              <barcode
                :width="1.7"
                height="53"
                font-size="11"
                :value="item.barcode"
              >
                Show this if the rendering fails.
              </barcode>
              <b-button
                variant="outline-primary"
                class="link"
                @click="showPrint(item.barcode, item.category.name_uz)"
              >
                <feather-icon
                  icon="PrinterIcon"
                  size="16"
                />
                Распечатать штрих-код
              </b-button>
            </div>
            <hr>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <!--  -->
    <b-modal
      v-model="show_print_barcode"
      size="sm"
      centered
      hide-footer
      no-close-on-backdrop
      @close="show_print_barcode = false"
      @cancel="show_print_barcode = false"
    >
      <div class="text-center">
        <Print
          :barcode="product_barcode"
          :name="product_name"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import vSelect from 'vue-select'
import VueBarcode from 'vue-barcode'
import Print from '@/views/print/print.vue'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver, vSelect, barcode: VueBarcode, Print,
  },
  props: {},
  data() {
    return {
      form: {
        id: null,
        amount: '',
        name_ru: '',
      },
      filterProduct: {
        level: 3,
        per_page: 9999,
        belongs_to_hired_worker: null,
        name_uz: '',
      },
      barcodesProduct: [],
      barcodeProduct: '',
      category: null,
      title: '',
      visible: false,
      show_print_barcode: false,
      product_barcode: '',
      product_name: '',
      required,
    }
  },
  computed: {
    ...mapGetters({
      product_categories: 'productCategory/GET_ITEMS',
    }),
    itemId() {
      return this.$route.query.id
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
        }, 200)
      }
    },
  },
  mounted() {
    this.title = this.$t('Mahsulot shtrix-kodini chop etish')
    this.$root.$on('add', data => {
      this.visible = true
    })
    this.fetchProducts()
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.$emit('onSuccess', this.category)
      } else {
        showToast('warning', this.$t('Mahsulotni tanlang'))
      }
    },
    getCities(cityId) {
      this.fetchCities({ region_id: cityId })
    },
    changeCategory(val) {
      this.getProductsForPriceTag({ category_id: val.id }).then(res => {
        this.barcodesProduct = res.data
        if (res.data && res.data.length) {
          this.barcodeProduct = res.data[0]
          this.changeBarcode(res.data[0])
        }
      })
    },
    changeBarcode(val) {
      this.category.barcode = val.barcode
      this.category.price = val.price
      this.category.price_monthly_12 = val.price_monthly_12
      this.category.price_monthly_18 = val.price_monthly_18
      this.category.price_monthly_24 = val.price_monthly_24
    },
    edit(item) {
      this.form.id = item.id
      this.form.name_uz = item.name_uz
      this.form.name_ru = item.name_ru
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    showPrint(barcode, name) {
      this.product_barcode = barcode
      this.product_name = name
      this.show_print_barcode = true
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    fetchProducts() {
      this.getCategories(this.filterProduct)
    },
    ...mapActions({
      getCategories: 'productCategory/index',
      getProductsForPriceTag: 'productCategory/productsForPriceTag',
      storeItem: 'resource/storeDollarRates',
      updateItem: 'resource/updateUnitOfMeasure',
    }),
  },
}
</script>

<style scoped></style>
